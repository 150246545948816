import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import './FileInput.css';

export default function FileInput({label, placeholder, value, onChange, style}){
    const intl = useIntl()
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const inputFile = useRef(null) 


    return (
        <>
            <Form.Label className="label">{label}</Form.Label>
            <div className="file-box-container"> 
                
                <Button
                    onClick={()=>{
                        inputFile.current.click()
                    }}
                >
                    {intl.formatMessage({id: "Choose File"})}
                </Button>
                <input 
                    ref={inputFile} type="file" id="imgupload" style={{display: 'none'}}
                    onChange={(e)=>{
                        const file = e.target.files[0];
                        if(file){
                            const filename = file.name
                            const fileType = file.type.split("/").pop()
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64String = reader.result
                                    // .replace('data:', '')
                                    // .replace(/^.+,/, '');
                                onChange(base64String, fileType);
                            };
                            reader.readAsDataURL(file);
                        }else{
                            onChange("", "")
                        }
                        
                    }}
                /> 
                  {
                    !isMobile &&
                    <Button>
                        {intl.formatMessage({id: "Take Photo"})}
                    </Button>
                  }
                  
                {/* <Form.Control
                    className="text-input"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={style}
                    type="text"
                /> */}
            </div>
        </>
    )
}