import moment from "moment";
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import AutoCompleteSelect from "../shared/AutoCompleteSelect";
import DatePicker from "../shared/DatePicker";
import FormActionButton from "../shared/FormActionButton";
import ItemsRadioOptions from "../shared/ItemsRadioOptions";
import ListRadioOptions from "../shared/ListRadioOptions";
import Select from "../shared/Select";
import Text from "../shared/Text";

export default function Passengers({compensate, onNext, onBack}){
    const [title, setTitle] = useState(compensate.title ?? 'Mr')
    const [firstName, setFirstName] = useState(compensate.firstName)
    const [surname, setSurname] = useState(compensate.surname)
    const [dob, setDob] = useState(compensate.dob)
    const [didYouBook, setdidYouBook] = useState(compensate.didYouBook)
    const intl = useIntl();

    const titleOptions = [
        {text: "Mr", value: "Mr"},
        {text: "Ms", value: "Ms"},
        {text: "Mx", value: "Mx"},
    ];

    return (
        <>
            <div className="heading">
                <FormattedMessage id="thankyouForProvidingFlightInformation"/>
            </div>
            <div className="form-content">
                
                <Select
                    label={intl.formatMessage({id: "whatIsYourTitle"})}
                    options={titleOptions}
                    onChange={setTitle}
                    value={title}
                />

                <Text 
                    label={intl.formatMessage({id: "whatIsYourFirstName"})}
                    onChange={(e)=>setFirstName(e.target.value)}
                    value={firstName}
                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                />

                <Text 
                    label={intl.formatMessage({id: "whatIsYourSurname"})}
                    onChange={(e)=>setSurname(e.target.value)}
                    value={surname}
                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                />

                <div className="info">
                    <FormattedMessage id="Please make sure that you enter your names as they appear on your identification."/>
                </div>

                <DatePicker
                    label={intl.formatMessage({id: "What is your date of birth?"})}
                    onChange={(e)=>setDob(e.target.value)}
                    value={dob}
                    min="1899-01-01"
                    max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                />

                <ItemsRadioOptions
                    label={intl.formatMessage({id: "Did you book and pay for this fight?"})}
                    items={[{text: "Yes", value: "Yes"}, {text: "No", value: "No"}]}
                    onSelect={setdidYouBook}
                    value={didYouBook}
                    style={{marginLeft: 0, marginRight: 0}}
                    topStyle={{textAlign: "Left"}}
                />
            </div>
            <FormActionButton
                onNext={()=>{
                    if(title && firstName && surname && dob && didYouBook){
                        onNext({
                            title,
                            firstName,
                            surname,
                            dob,
                            didYouBook
                        }, (COMPENSATE_SCREEN.PASSENGERS+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.PASSENGERS-1)
                }}
            />
        </>
    )
}