import { Form } from "react-bootstrap";
import './DatePicker.css'

export default function DatePicker(props){
    const {label, value, onChange, style} = props;
    return (
        <>
            <Form.Label className="label">{label}</Form.Label>
            <Form.Control 
                {...props}
                className="date-picker"
                type="date"
                value={value}
                onChange={onChange}
                style={style}
            />
        </>
    )
}