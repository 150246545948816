import React, { useState, useRef, useLayoutEffect } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = (props) => {
    const [showElement, setShowElement] = useState(false);

    const stickyHeader = useRef()
    useLayoutEffect(() => {
        const mainHeader = document.getElementById('main_header')
        let fixedTop = stickyHeader.current.offsetTop
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('fixed-top')
            } else {
                mainHeader.classList.remove('fixed-top')
            }
        }
        window.addEventListener('scroll', fixedHeader)
    }, [])

    const showMenu = () => {
        setShowElement(true);
    }

    const closeMenu = () => {
        setShowElement(false);
    }

    return (
        <header className="header-wrapper" id="main_header" style={{ backgroundColor: props.bg }} ref={stickyHeader}>
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className="w-100-sm d-flex align-items-center justify-content-between">
                        <div className="site-logo">
                            <Link to="/home"><img src="img/site-logo.png" alt="" /></Link>
                        </div>
                        <button className="mobile-menu" onClick={showMenu}><i class="fa-solid fa-bars"></i></button>
                    </div>
                    <ul className="ms-auto navbar lg">
                        <li> <NavLink className="nav-bar-link" to="/home">Home</NavLink> </li>
                        {/* <li> <NavLink className="nav-bar-link" to="/solutions">Solutions</NavLink> </li> */}
                        <li> <NavLink className="nav-bar-link" to="/faq-page">FAQ’s</NavLink> </li>
                        {/* <li> <NavLink className="nav-bar-link" to="/how-it-works">How it works</NavLink> </li> */}
                        <li> <NavLink className="nav-bar-link" to="/conatct-page">Conatct</NavLink> </li>
                        <li> <NavLink className="nav-bar-link btn-w color3" to="/view-claims">View Claims</NavLink></li>
                    </ul>

                    {showElement ?
                        <ul className="ms-auto navbar sm">
                            <li> <NavLink className="nav-bar-link" to="/home">Home</NavLink> </li>
                            {/* <li> <NavLink className="nav-bar-link" to="/solutions">Solutions</NavLink> </li> */}
                            <li> <NavLink className="nav-bar-link" to="/faq-page">FAQ’s</NavLink> </li>
                            {/* <li> <NavLink className="nav-bar-link" to="/how-it-works">How it works</NavLink> </li> */}
                            <li> <NavLink className="nav-bar-link" to="/conatct-page">Conatct</NavLink> </li>
                            <li> <NavLink className="nav-bar-link btn-w color3" to="/view-claims">View Claims</NavLink></li>
                            <li className="close-menu" onClick={closeMenu}><i class="fa-solid fa-xmark"></i></li>
                        </ul>
                        : null
                    }
                </div>
            </div>
        </header>
    )
}

export default Header;