import { FormattedMessage } from "react-intl";

export default function End(){
    return (
        <>
            <div className="heading">
                <FormattedMessage id="Thank you for submitting your Claim!"/>
            </div>

            <div className="form-content">
                <div className="box-message">
                    <FormattedMessage id="Our team will be in touch within XXX days. In the meantime, if you have any questions please call us on XXXXX."/>
                </div>
            </div>
        </>
    )
}