const COMPENSATE_SCREEN_ = {
  NUMBER_OF_PASSENGERS: 0,
  START_DESTINATION: 0,
  DEPARTURE_DATE: 0, //FLIGHT_SELECT
  MANUAL_FLIGHT: 0,
  AIRLINES_DETAIL: 0,
  DELAY_REASON_1: 0,
  // DELAY_REASON_2: 0,
  DELAY_REASON_3: 0,
  PASSENGERS: 0,
  ADDRESS: 0,
  CONTACT_DETAILS: 0,
  OTHER_PASSENGERS: 0,
  SIGNATURE: 0,
  EVIDENCE: 0,
  OTHER_COMMENTS: 0,
  END: 0
};
Object.keys(COMPENSATE_SCREEN_).forEach(
  (key, index) => (COMPENSATE_SCREEN_[key] = index)
);
export const COMPENSATE_SCREEN = COMPENSATE_SCREEN_;

export const EACH_PASSENGER_CLAIM = 520;

export const COMPENSATION_SUBMIT_ENDPOINT =
  'https://datastore.tanantech.co.uk/1.0/data';

export const GET_ALL_AIRLINES_ENDPOINT =
  'https://flights.tanantech.co.uk/1.0/all_airlines';
export const GET_ALL_AIRPORT_ENDPOINT =
  'https://flights.tanantech.co.uk/1.0/all_airports';
export const SEARCH_AIRPORT_ENDPOINT =
  'https://flights.tanantech.co.uk/1.0/airport_search';
export const GET_ADDRESS_LOOKUP_ENDPOINT =
  'https://flights.tanantech.co.uk/1.0/address_lookup';
export const GET_FLIGHT_ENDPOINT =
  'https://flights.tanantech.co.uk/1.0/flight_search';

//export const CRM_SUBMIT_ENDPOINT = "https://crm.airtravelclaim.co.uk/api/1.0/cases";
export const CRM_SUBMIT_ENDPOINT = 'https://mydigitalassets.in/create-claim';
export const CRM_SUBMIT_AUTH = 'Token 3b87fab4-b690-40a7-91fe-54e1039d6454';
