import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Policy = () => {
    return (
        <div className="au-wrapp">
            <Header bg='#EAEAEA' />
            <div className="cp-wrapp gap gft">
                <div className="container">
                    <h2 className="title text-center">Policy Documentation </h2>
                    <div className="gap-top-sm">
                        <h3 className="title">About</h3>
                        <div className="mb-3">Air Travel Claims are committed to protecting the privacy of our website visitors and our customers; this policy sets out how we will treat your personal information. Throughout the policy, we refer to your personal information as your data.</div>
                        <div className="mb-3">
                            If you have any questions about this privacy policy or our treatment of your personal data, please contact us:
                        </div>
                        <ul>
                            <li> by email at: info@airtravelclaim.com</li>
                            <li> by post to: Air Travel Claim. 84 Salop Street Wolverhampton WV3 0SR.</li>
                            <li> by telephone on: 0330 808 8636</li>
                        </ul>
                        <div className="mb-3">We are a Data Controller, which means that we determine the purposes and the means of processing your personal information. We are registered directly with the Information Commissioner’s Office (ICO) and our data protection registration number is: ZA067879.</div>
                        <h3 className="title">Why we need your personal information</h3>
                        <div className="mb-3">
                            We obtain personal information from you. During the provision of our services, you agree for us to process your personal information through signing our Claim Documents to allow us to:
                        </div>
                        <ul>
                            <li> Contact you via the contact methods you have chosen to provide to us, including text message, messaging platforms such as WhatsApp, telephone, email and/or post at any time.</li>
                            <li>Contact you about your potential flight disruption complaints, and other similar products and services.</li>
                            <li>Submit a complaint to the airline.</li>
                            <li>Submit a review of your complaint with the airline’s Alternative Dispute Resolution scheme.</li>
                            <li>Provide your complaint details to one of our legal panel partners to allow them to consider commencing a County Court claim.</li>
                            <li>Administer our website, including ensuring that we can enable your use of the services on our website (including enquiries and complaints about our website) and improve your browsing experience by personalising the website.</li>
                        </ul>
                        <div className="mb-3">
                            During the provision of our services, you agree for us to process your personal information through signing our Letter of Authority which means you agree to our Terms and Conditions, to allow us to:
                        </div>
                        <ul>
                            <li>Supply to you services under your contract.</li>
                            <li>Send you general commercial communications by the contact details you have provided to us during our services.</li>
                            <li>Contact third parties on your behalf, with your specific instruction.</li>
                            <li>Send you email notifications which you have specifically requested; and</li>
                            <li>Send statements and invoices to you and collect payments from you.</li>
                        </ul>
                        <h3 className="title">Legitimate Interest</h3>
                        <div className="mb-3"> We have a legitimate interest in keeping you updated about any further similar services offered by us which may be of interest to you, both during and after the conclusion of your contract with us, including communication of any developments that may have an impact on your original service including a change in the law that will make additional services available to you. We will use the details you have provided to contact you including by telephone, email, and post. If you do not wish for us to contact you in this manner, or by a specific method, you will be able to unsubscribe at any time to one or all contact methods and this option will be easy for you to complete.</div>

                        <div className="mb-3">We also have a legitimate interest in using your data to help us to review our services and obtain analytics in respect of our customer base.</div>

                        <div className="mb-3">We may also be required to use your data due to a legal requirement which is placed upon us; this includes our regulatory requirements such as financial record keeping, staff training and monitoring, in addition to complaint handling. In these circumstances, we may be required to keep your data by law. We will always inform you if this is the case.
                        </div>

                        <h3 className="title">What data do we collect?</h3>
                        <div className="mb-3">We collect, store, and use the following kinds of your data:</div>

                        <ul>
                            <li>
                                information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type, referral source, length of visit and number of page views).</li>
                            <li>information relating to any transactions carried out between you and us on or in relation to this website, including information relating to the supply of our services.</li>
                            <li>information that you provide to us for the purpose of registering with us (including name, address, telephone numbers, email addresses, date of birth, previous addresses, and any flight information).</li>
                            <li>information that you provide to us for the purpose of subscribing to our website services, email notifications and/or newsletters).</li>
                            <li>information required to complete our services that we will request directly from you, including your personal circumstances and information about your flight disruption and services; and</li>
                            <li>any other information that you choose to send to us.
                            </li>
                        </ul>

                        <div className="mb-3">If you do not provide the personal information we have requested, it may affect our ability to provide the services that we offer, and you will be informed of this at the appropriate time.</div>

                        <div className="mb-3">We control the use of your data as you have expressed.</div>
                        <h3 className="title">Who we share data with</h3>
                        <div className="mb-3">
                            To allow us to provide our services to you, the following third parties provide critical functions to our business and will process your personal information as directed by us and in accordance with strict data security arrangements:</div>

                        <ul>
                            <li>Our Services: during our services we will provide your data, under your specific instruction, to named airlines, booking agents and, if required, our partner law firms.</li>
                            <li>Our Systems and IT: we use third party firms who provide and support to our CRM infrastructure.</li>
                            <li>Post and Printing: we use an outsourced print-house who manages our printing, they receive a copy of postal communications and print and send this on our behalf.</li>
                            <li>Our Regulators: we may be required to provide your data to our Regulators, who include the Financial Conduct Authority, the Legal Ombudsman Service and the Information Commissioner’s Office; and</li>
                            <li>Our Professional Services: we use professional legal, consultancy and accountancy services to help us fulfil our legal obligations.
                            </li>
                        </ul>

                        <div className="mb-3">We do not sell your data to third parties in any circumstances. We have carefully selected our third parties based on their ability to keep your data safe and secure. All data is processed within the European Union and subject to the same legislation. If you request for us to stop processing your data, we will also communicate this to the relevant third parties if they are processing this on our behalf. If you have any concerns about the above third parties, please let us know and we can provide advice and support to help you manage your data preferences.
                        </div>
                        <h3 className="title">
                            Data disclosure
                        </h3>
                        <div className="mb-3">
                            We may disclose information about you to any of our employees, officers, agents, suppliers, or subcontractors insofar as reasonably necessary for the purposes as set out in this privacy policy.
                            In addition, we may disclose information about you:</div>

                        <ul>
                            <li>
                                to the extent that we are required to do so by law.</li>
                            <li>in connection with any legal proceedings or prospective legal proceedings; and</li>
                            <li>in order to establish, exercise or defend our legal rights (including, providing information to others for the purposes debt collection, fraud prevention, and reducing credit risk).
                            </li>
                        </ul>

                        <h3 className="title">Data transfer</h3>
                        <div className="mb-3">
                            We do not specifically transfer your data to countries outside of the European Union. However, some of our third-party service providers may provide services which mean your data is transferred outside of the European Union. In any case, we ensure that there are appropriate security measures in place, including data encryption and restricted access to your data. The only circumstance in which this may happen is if one of our third-party data processors backs up their systems (to keep their data secure) outside of the European Union. In any event, we have strict contracts in place to make sure your data is kept safe, and your data is always under our control. If you have any questions, please feel free to contact us for further information.
                        </div>

                        <h3 className="title">Other websites</h3>
                        <div className="mb-3">Our website may contain links to other websites. We are not responsible for the privacy policies or practices of third-party websites.</div>

                        <h3 className="title">General</h3>
                        <div className="mb-3">We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page periodically to ensure you are happy with any changes. We shall communicate with you if we make any changes that directly affect you.</div>
                        <h3 className="title">Cookies</h3>
                        <div className="mb-3">
                            A cookie consists of information sent by a web server to a web browser and is stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser. </div>
                        <div className="mb-3">For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on your browser or the hard drive of your computer. Cookies contain information that is transferred to your computer’s hard drive. They help us to improve our site and to deliver a better and more personalized service. Some of the cookies we use are essential for the site to operate. They enable us to: </div>

                        <ul>
                            <li>
                                Estimate our audience size and usage pattern.</li>
                            <li>Store information about your preferences, and to allow us to customize our site according to your individual interests.</li>
                            <li>Speed up your searches.</li>
                            <li>Recognise you when you return to our site.
                            </li>
                        </ul>

                        <div className="mb-3">If you register with us or if you continue to use our site, you agree to our use of cookies.
                            You block cookies by activating the setting on your browser which allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be to access all or parts of our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies as soon you visit our site.
                            You may also wish to visit: http://www.allaboutcookies.org for further information.
                        </div>

                        <h3 className="title">Your rights</h3>
                        <div className="mb-3">
                            You can control, access, or restrict the use of your data, and you have rights under this policy. You can withdraw your consent for us to contact you at any time. Please note: if you have signed a contract to complete a claim, our contractual rights will still apply, and we may contact you in relation to a cancellation fee or any outstanding monies owed to us.</div>

                        <div className="mb-3">We will keep your contact details following an enquiry to our website for a period of three years.</div>

                        <div className="mb-3">We will keep your contact details following the conclusion of our services for a minimum period of three years. We may be required to keep your data for longer to fulfil our legal obligations, including our accounting requirements and our regulatory obligations, such as complaints handling.</div>

                        <div className="mb-3">This enables us to provide our services to you and fulfil our legal obligations. We will take reasonable technical and organizational precautions to secure your personal data and to prevent the loss, misuse, or alteration of your personal data. We will store all the personal information you provide on our secure servers that are subject to strict security requirements.</div>

                        <div className="mb-3">If you request the rectification, erasure, or restriction of your data, we will also communicate this to any third party who your data has been disclosed to.</div>

                        <div className="mb-3">In exercising any of your rights, we will take action within thirty (30) days. We will need to confirm your identity before completing any action on your behalf and reserve the right to not complete action until we are satisfied that it is you that is making the request. If we cannot complete your request, we will inform you within one month and explain why.</div>

                        <div className="mb-3">Please note, we require certain personal information to be able to complete our services. If you do not provide this, we may not be able to fulfil our contract with you. In this event, we will explain what data is required and why. If you do not wish to provide this, we have the right to terminate your contract with us.</div>

                        <div className="mb-3">You can access your data at any time by submitting a Subject Access Request (“SAR”). We will confirm what data is being processed and provide you with a copy of your data in addition to confirming your data rights.</div>

                        <div className="mb-3">You can make this request using reasonable means, including by telephone, post, or email. If you make the request by email, we will provide your information in a commonly used electronic format unless you instruct us otherwise.</div>

                        <div className="mb-3">If you wish to amend any inaccurate data that we hold, please notify us specifically by telephone, post, or email. We will make the amendment as soon as possible. If any data held is incomplete, you can complete this at any time. We may require this to be completed to allow us to provide our services.</div>

                        <div className="mb-3">We can erase or “forget” your data when requested by you in the following circumstances:</div>

                        <ul>
                            <li>
                                We no longer require your data (when we have finished providing any services requested by you).</li>
                            <li>You withdraw your consent and there is no other reason for us to hold your data (please note: if you have signed a contract to complete a claim, our contractual rights will still apply, and we can contact you in relation to a cancellation fee or any outstanding monies owed to us).</li>
                            <li>You object to the processing, as described below.</li>
                            <li>You believe your data has been unlawfully processed; or</li>
                            <li>There is a legal obligation to erase your data.
                            </li>
                        </ul>

                        <div className="mb-1">You can make this request using reasonable means including by telephone, post, or email. If you request for your data to be erased, we will confirm whether this can take place and the next steps that we will take. If we cannot erase your data, we will explain why and confirm any actions required to allow us to do so.</div>
                        <div className="mb-3">You can restrict us from processing your data in the following circumstances:</div>
                        <ul>
                            <li>
                                You believe your data is inaccurate.</li>
                            <li>You believe your data has been unlawfully processed but do not want us to delete your data.</li>
                            <li> We no longer need your data, but it is required by you for making or defending a legal claim; or</li>
                            <li> You object to the processing, as described below, but we are verifying this.
                            </li>
                        </ul>

                        <div className="mb-3">If you make a restriction request, we will still store a copy of your data but cannot use this. We will inform you if the restriction needs to be lifted. You can make this request using reasonable means including by telephone, post, or email. If you request for your data to be restricted, we will confirm whether this can take place and the next steps that we will take. If we cannot restrict your data, we will explain why and confirm any actions required to allow us to do so.</div>

                        <div className="mb-3">You can object to the processing of your data at any time. This right will only apply if we are using your data in relation to a legitimate interest. If you object, we will no longer process your data unless we have a compelling and legitimate reason not to do so (e.g., a legal obligation). In this case, you will be informed why we cannot stop processing your data.
                        </div>
                        <h3 className="title">You can opt out at any time to stop receiving marketing from us.</h3>
                        <div className="mb-3">You can request your data in a commonly used electronic format, and for us to transfer this to another entity or person, where we are processing your data with your consent or in accordance with a contract. This will only apply to information which is processed by automated means (e.g., by a computer). Where feasible, we will send your data directly to another entity or person.</div>

                        <h3 className="title">Withdrawing your Consent</h3>
                        <div className="mb-3">You can withdraw your consent for us to contact you at any time. You can withdraw your consent for us to contact you by telephone, text message (SMS), messaging platforms such as WhatsApp, email and/or post at any time. You can withdraw consent verbally via telephone or in writing via email or post using the contact details at the end of this policy. Please note: if you have signed a contract to complete a claim, our contractual rights will still apply, and we can contact you in relation to a cancellation fee, or any outstanding monies owed to us</div>

                        <h3 className="title">Complaints</h3>
                        <div className="mb-3">If you are not happy with how we process your personal information, you should contact us using the contact details provided above, to make a complaint You also have the right to make a complaint to the Information Commissioner’s Office (ICO) if you are unhappy with how we have used your personal information, for example, if we were to refuse to comply with a data rights request. We would, however, prefer that you direct any complaints us in the first instance to we may attempt to resolve your concerns. You can find details of how to make a complaint to the ICO on their website, at https://ico.org.uk/make-a-complaint/</div>

                        <h3 className="title">ICO’s Contact Details</h3>
                        <div className="mb-3">
                            We are Regulated by the Information Commissioner’s Office (ICO) in respect of our obligations to protect your data. You have the right to make a complaint about our use of your data to the ICO, who will investigate this on your behalf. The ICO will only investigate complaints made within three months of your last contact with us.</div>

                        <div className="mb-1">You can contact the ICO through their </div>
                        <div className="mb-1">website: https://ico.org.uk or </div>
                        <div className="mb-1">by telephone: 0303 123 1113, or </div>
                        <div className="mb-3">by post at: Information Commissioner’s Office Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF</div>

                        <div className="mb-3">If you have any queries concerning this policy and the use of your personal data, </div>

                        <div className="mb-3">please email info@airtravelclaim.com</div>

                        <div className="mb-3">We may update this privacy Policy from time-to-time by posting a new version on our website at www.airtravelclaim.com You should check this page occasionally to ensure you are happy with any changes
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Policy;