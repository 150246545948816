import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import Text from "../shared/Text";

export default function DelayReason3({compensate, onNext, onBack}){
    const [delayReason3, setDelayReason3] = useState(compensate.delayReason3)
    const [howManyHoursLate, setHowManyHoursLate] = useState(compensate.howManyHoursLate)
    const [causedTheDelay, setCausedTheDelay] = useState(compensate.causedTheDelay)

    const [whenInformedFlightCancelled, setWhenInformedFlightCancelled] = useState(compensate.whenInformedFlightCancelled)
    const [wereOfferedAlternativeFlight, setWereOfferedAlternativeFlight] = useState(compensate.wereOfferedAlternativeFlight)
    const [alternativeFlightNumber, setAlternativeFlightNumber] = useState(compensate.alternativeFlightNumber)

    const [checkInForBoarding, setCheckInForBoarding] = useState(compensate.checkInForBoarding)

    const intl = useIntl();

    const delayReasons = [
        {text: "My fight was delayed.", value: "My fight was delayed."},
        {text: "My fight was cancelled", value: "My fight was cancelled"},
        {text: "I was denied boarding", value: "I was denied boarding"},
        {text: "My fight was rerouted", value: "My fight was rerouted"},
        {text: "My fight was returned", value: "My fight was returned"},
    ];

    const howManyHourLatesOptions = [
        {text: "0 - 1 hours", value: "0 - 1 hours"},
        {text: "1 - 2 hours", value: "1 - 2 hours"},
        {text: "2 - 3 hours", value: "2 - 3 hours"},
        {text: "3 - 4 hours", value: "3 - 4 hours"},
        {text: "4+ hours", value: "4+ hours"},
        {text: "Never arrived", value: "Never arrived"},
    ];

    const causedTheDelayOptions = [
        {text: "Technical Fault", value: "Technical Fault"},
        {text: "Industrial Action", value: "Industrial Action"},
        {text: "Weather", value: "Weather Problems"},
        {text: "Problems at airport", value: "Problems at airport"},
        {text: "Extraordinary Circumstances", value: "Extraordinary Circumstances"},
        {text: "Don’t know", value: "Don’t know"},
    ];

    const boardingDeniedOptions = [
        {text: "Flight overbooked", value: "Flight overbooked"},
        {text: "Intoxication or under the infuence of drugs", value: "Intoxication or under the infuence of drugs"},
        {text: "Unruly or disruptive behaviour", value: "Unruly or disruptive behaviour"},
        {text: "I volunteered to take a later fight", value: "Problems at airport"},
    ];

    const whenInformedFlightCancelledOptions = [
        {text: "More than 14 days before departure.", value: "More than 14 days before departure."},
        {text: "7 to 14 days departure.", value: "7 to 14 days departure."},
        {text: "Less than 7 days before departure", value: "Less than 7 days before departure"},
    ];

    const FlightDelayed = () => {
        return (
            <>
                <ListRadioOptions
                    label={intl.formatMessage({id: "howManyHoursLate"})}
                    items={howManyHourLatesOptions}
                    onSelect={setHowManyHoursLate}
                    value={howManyHoursLate}
                />

                <ListRadioOptions
                    label={intl.formatMessage({id: "whatAirlinesSayCausedDelay"})}
                    items={causedTheDelayOptions}
                    onSelect={setCausedTheDelay}
                    value={causedTheDelay}
                />
            </>
        )
    }

    const flightCancelled = () => {
        return (
            <>

                <ListRadioOptions
                    label={intl.formatMessage({id: "When were you informed the fight was cancelled?"})}
                    items={whenInformedFlightCancelledOptions}
                    onSelect={setWhenInformedFlightCancelled}
                    value={whenInformedFlightCancelled}
                />

                <ListRadioOptions
                    label={intl.formatMessage({id: "Were you ofered an alternative fight?"})}
                    items={[{text: "Yes", value: "Yes"}, {text: "No", value: "No"}]}
                    onSelect={(val) => {
                        if(val === "No"){
                            setAlternativeFlightNumber("");
                        }
                        setWereOfferedAlternativeFlight(val)
                    }}
                    value={wereOfferedAlternativeFlight}
                />

                {
                    wereOfferedAlternativeFlight === "Yes" &&
                    <Text
                        label={intl.formatMessage({id: "What was the alternative fight number?"})}
                        onChange={(e)=>setAlternativeFlightNumber(e.target.value)}
                        value={alternativeFlightNumber}
                    />
                }

                <ListRadioOptions
                    label={intl.formatMessage({id: "howManyHoursLate"})}
                    items={howManyHourLatesOptions}
                    onSelect={setHowManyHoursLate}
                    value={howManyHoursLate}
                />

                <ListRadioOptions
                    label={intl.formatMessage({id: "whatAirlinesSayCausedDelay"})}
                    items={causedTheDelayOptions}
                    onSelect={setCausedTheDelay}
                    value={causedTheDelay}
                />
            </>
        )
    }

    const FlightDenied = () => {
        return (
            <>
                <ListRadioOptions
                    label={intl.formatMessage({id: "Did you check in and present yourself for boarding?"})}
                    items={[{text: "Yes", value: "Yes"}, {text: "No", value: "No"}]}
                    onSelect={setCheckInForBoarding}
                    value={checkInForBoarding}
                />
                <ListRadioOptions
                    label={intl.formatMessage({id: "What reason were you given for being denied boarding?"})}
                    items={boardingDeniedOptions}
                    onSelect={setCausedTheDelay}
                    value={causedTheDelay}
                />
                <ListRadioOptions
                    label={intl.formatMessage({id: "howManyHoursLate"})}
                    items={howManyHourLatesOptions}
                    onSelect={setHowManyHoursLate}
                    value={howManyHoursLate}
                />
            </>
        )
    }

    const renderReasonQuestionBlock = () => {
        if(!delayReason3) return <></>;
        switch(delayReason3){
            case delayReasons[1].value:
                return flightCancelled();
            case delayReasons[2].value:
                return <FlightDenied/>
            default:
                return <FlightDelayed/>
        }

    }

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail"/>
            </div>
            <div className="form-content">

                <ListRadioOptions
                    label={intl.formatMessage({id: "whyFlightDelayed"})}
                    items={delayReasons}
                    onSelect={setDelayReason3}
                    value={delayReason3}
                />

                {
                    renderReasonQuestionBlock()
                }
            </div>
            <FormActionButton
                onNext={()=>{
                    if(delayReason3 && howManyHoursLate && causedTheDelay){
                        onNext({
                            delayReason3,
                            howManyHoursLate,
                            causedTheDelay,
                            whenInformedFlightCancelled,
                            wereOfferedAlternativeFlight,
                            alternativeFlightNumber,
                            checkInForBoarding
                        }, (COMPENSATE_SCREEN.DELAY_REASON_3+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.DELAY_REASON_3-1)
                }}
            />
        </>
    )
}