import { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import SignatureCanvas from 'react-signature-canvas'
import Label from "../shared/Label";
import { cloneDeep } from "lodash";

export default function Signature({compensate, onNext, onBack}){
    const [signature, setSignature] = useState(compensate.signature)
    const [termsConditions, setTermsConditions] = useState(compensate.termsConditions)
    const [termsConditions2, setTermsConditions2] = useState(compensate.termsConditions2)
    const [signaturePadWidth, setSignaturePadWidth] = useState()
    const [clients, setClients] = useState(compensate.clients)
    const [otherClientSign, setOtherClientSign] = useState()
    const signatureRef = useRef();
    const intl = useIntl();

    useEffect(() => {
        setTimeout(()=>{
            setSignaturePadWidth(signatureRef?.current?._canvas?.parentElement?.clientWidth)
        })

        clients?.forEach((client, i)=>{
            if(client.availableForSignature){
                setOtherClientSign(i)
            }
        })
    }, [])

    const claimWorth = () => compensate.passengerCountInParty?.match(/\d+/g) * EACH_PASSENGER_CLAIM;

    return (
        <>
            <div className="heading">
                <FormattedMessage id="Great News! You could be owed £{clamAmount} in compensation! Sign below to allow us to progress your claim." values={{clamAmount: claimWorth()}}/>
            </div>
            <div className="form-content">
                
                <Label
                    label={intl.formatMessage({id: "{name} Sign Here*"}, {name: [compensate.firstName, compensate.surname].join(" ")})}
                />
                <div style={{background: '#F2F2F2 0% 0% no-repeat padding-box', border: '1px solid #707070'}}>
                    <SignatureCanvas 
                        penColor='black'
                        canvasProps={{height: 150, width: signaturePadWidth}}
                        onEnd={()=>{
                            setSignature(signatureRef.current._sigPad.toDataURL('image/svg+xml'))
                        }}
                        ref={signatureRef}
                    />
                </div>

                <ListRadioOptions
                    items={[{text:intl.formatMessage({id: "I agree to the Terms and Conditions *"}), value: true}]}
                    onSelect={setTermsConditions}
                    value={termsConditions}
                />

                {
                    otherClientSign !== undefined &&
                    <>
                        <Label
                            label={intl.formatMessage({id: "{name} Sign Here*"}, {name: [clients[otherClientSign].firstname, clients[otherClientSign].lastname].join(" ")})}
                        />
                        <div style={{background: '#F2F2F2 0% 0% no-repeat padding-box', border: '1px solid #707070'}}>
                            <SignatureCanvas 
                                penColor='black'
                                canvasProps={{height: 150, width: signaturePadWidth}}
                                onEnd={()=>{
                                    const clients_ = cloneDeep(clients);
                                    clients_[otherClientSign].signature = signatureRef.current._sigPad.toDataURL('image/svg+xml');
                                    setClients(clients_);
                                }}
                                ref={signatureRef}
                            />
                        </div>

                        <ListRadioOptions
                            items={[{text:intl.formatMessage({id: "I agree to the Terms and Conditions *"}), value: true}]}
                            onSelect={setTermsConditions2}
                            value={termsConditions2}
                        />
                    </>
                }

                
            </div>
            <FormActionButton
                onNext={()=>{
                    if(
                        (signature && termsConditions) && 
                        (
                            otherClientSign === undefined ||
                            (
                                clients[otherClientSign].signature &&
                                termsConditions2
                            )
                        )
                    ){
                        onNext({
                            signature,
                            termsConditions,
                            clients
                        }, (COMPENSATE_SCREEN.SIGNATURE+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.SIGNATURE- ( compensate.passengerCountInParty != "1" ? 1 : 2 ))
                }}
            />
        </>
    )
}