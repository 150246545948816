import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_FLIGHT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import DatePicker from "../shared/DatePicker";
import axios from "axios";
import moment from "moment";

export default function DepartureDate({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [departureDate, setDepartureDate] = useState(compensate.departureDate)

    const [isFlightsLoading, setFlightsLoading] = useState(false);
    const [flightsList, setFlightsList] = useState(compensate.flightsList ?? []);
    const [flightsRecords, setflightsRecords] = useState(compensate.flightsRecords ?? []);

    const loadFlights = (departureDate_) => {
        if (compensate?.departureAirport?.value && compensate?.destinationAirport?.value) {

            setFlightsLoading(true);
            const data = {
                "arrivalAirport": compensate?.destinationAirport?.value,
                "departureAirport": compensate?.departureAirport?.value,
                "departureDate": departureDate_
            };

            axios.post(
                GET_FLIGHT_ENDPOINT,
                data
            ).then(async flights => {

                const AirlinesMap = {};
                const allAirlines = await axios.get('https://flights.tanantech.co.uk/1.0/all_airlines');
                allAirlines?.data?.data?.forEach(airline => AirlinesMap[airline.iata] = airline);

                const flightsList_ = flights?.data?.data?.[0]?.map(flight => {
                    let flightLabel = '';
                    try {
                        flightLabel = `${moment(flight.scheduledDepartureDate).format('HH:mm')} - ${moment(flight.scheduledDepartureDate).format('HH:mm')} ${AirlinesMap[flight.airlineCode]?.airlineName ?? ' - '}`
                    } catch (e) {
                        console.log('format error', e)
                    }
                    return {
                        ...flight,
                        flightLabel,
                        airline: AirlinesMap[flight.airlineCode] ?
                            {
                                label: AirlinesMap[flight.airlineCode]?.airlineName,
                                value: AirlinesMap[flight.airlineCode]?.iata
                            } : null
                    }
                }) ?? [];
                setflightsRecords(flightsList_);
                setFlightsList(flightsList_?.filter(o => o?.airline)?.map(data => ({ text: data.flightLabel, value: data.flightLabel })));
            })
                .catch(e => {
                    setflightsRecords([])
                    setFlightsList([])
                    console.log(e);
                })
                .finally(() => {
                    setFlightsLoading(false);
                })
        }
    }

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail" />
            </div>
            <div className="form-content">
                <DatePicker
                    label={intl.formatMessage({ id: "whatIsScheduleDeparture" })}
                    onChange={(e) => {
                        loadFlights(e.target.value);
                        setDepartureDate(e.target.value);
                    }}
                    value={departureDate}
                />
            </div>
            <FormActionButton
                onNext={() => {
                    if (departureDate && !isFlightsLoading) {
                        onNext({
                            flightsList,
                            departureDate,
                            flightsRecords,
                        }, (COMPENSATE_SCREEN.DEPARTURE_DATE + (flightsList.length ? 1 : 2)))
                    }
                }}
                onBack={() => {
                    onBack(COMPENSATE_SCREEN.DEPARTURE_DATE - 1)
                }}
            />
        </>
    )
}