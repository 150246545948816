import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FileInput from "../shared/FileInput";
import FormActionButton from "../shared/FormActionButton";
import TextArea from "../shared/TextArea";

export default function Evidence({onBack, onNext, compensate}){
    const [evidence, setEvidence] = useState(compensate.evidence)
    const [evidenceMime, setEvidenceMime] = useState(compensate.evidenceMime)
    const intl = useIntl();

    return (
        <>
            <div className="heading">
                <FormattedMessage id="Almost done! To progress your claim as quickly as possible we need some additional information."/>
            </div>
            <div className="form-content">
                <div className="info">
                    <FormattedMessage id="Please upload your boarding pass or e-ticket"/>
                </div>

                <FileInput 
                    value={evidence}
                    onChange={(file, mime)=>{
                        setEvidence(file)
                        setEvidenceMime(mime)
                    }}
                />

                <div className="info">
                    <FormattedMessage id="Make sure to retain all original documents."/>
                </div>
            </div>
            
            <FormActionButton
                onNext={()=>{
                    if(evidence){
                        onNext({
                            evidence,
                            evidenceMime
                        }, (COMPENSATE_SCREEN.EVIDENCE+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.EVIDENCE-1)
                }}
                submitTitle={intl.formatMessage({id: "Submit Your Claim"})}
            />
        </>
    )
}