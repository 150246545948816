import { Form } from "react-bootstrap";
import './Text.css';

export default function Text({label, placeholder, value, onChange, style}){
    return (
        <>
            <Form.Label className="label">{label}</Form.Label>
            <Form.Control
                className="text-input"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={style}
                type="text"
            />
        </>
    )
}