import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import Text from "../shared/Text";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Label from "../shared/Label";

export default function Address({ compensate, onNext, onBack }) {
    const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
    const [address, setAddress] = useState(compensate.address)
    const [addressLine2, setAddressLine2] = useState(compensate.addressLine2)
    const [city, setCity] = useState(compensate.city)
    const [postcode, setPostcode] = useState(compensate.postcode)
    const [county, setCounty] = useState(compensate.county)
    const [country, setCountry] = useState(compensate.country)
    const intl = useIntl();

    const mapAddress = (place) => {
        let address1 = "";
        let address2 = "";
        for (const component of (place?.address_components ?? [])) {
            const componentType = component.types[0];
            switch (componentType) {
                case "sublocality_level_3": {
                    if (!address1) address1 = component.long_name;
                    else address2 = component.long_name;
                    break;
                }
                case "sublocality_level_2": {
                    if (!address1) address1 = component.long_name;
                    else address2 = component.long_name;
                    break;
                }
                case "sublocality_level_1": {
                    if (!address1) address1 = component.long_name;
                    else address2 = component.long_name;
                    break;
                }
                case "administrative_area_level_4": {
                    if (!address1) address1 = component.long_name;
                    else address2 = component.long_name;
                    break;
                }
                case "locality":
                    setCity(component.long_name);
                    break;
                case "postal_code":
                    setPostcode(component.long_name);
                    break;
                case "administrative_area_level_2":
                    setCounty(component.long_name);
                    break;
                case "country":
                    setCountry(component.long_name);
                    break;
            }
        }
        setAddress(address1);
        setAddressLine2(address2);
    }

    return (
        <>
            <div className="heading">
                <FormattedMessage id="Your Details" />
            </div>
            <div className="form-content">
                <Label label={intl.formatMessage({ id: "Address *" })} />
                <ReactGoogleAutocomplete
                    apiKey={MAP_API_KEY}
                    options={{ types: ['geocode'] }}
                    onPlaceSelected={place => mapAddress(place)}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                    className={'text-input'}
                    placeholder={''}
                    onChange={(e) => setAddress(e.target.value)}
                />

                <Text
                    label={intl.formatMessage({ id: "Address Line 2" })}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    value={addressLine2}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                />

                <Text
                    label={intl.formatMessage({ id: "City *" })}
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                />

                <Text
                    label={intl.formatMessage({ id: "Postcode *" })}
                    onChange={(e) => setPostcode(e.target.value)}
                    value={postcode}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                />

                {/* <Text
                    label={intl.formatMessage({ id: "County" })}
                    onChange={(e) => setCounty(e.target.value)}
                    value={county}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                /> */}

                <Text
                    label={intl.formatMessage({ id: "Country" })}
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                    style={{ border: 'none', borderBottom: '1px solid #707070' }}
                />
            </div>
            <FormActionButton
                onNext={() => {
                    if (address && city && postcode && country) {
                        onNext({
                            address,
                            addressLine2,
                            city,
                            postcode,
                            country
                        }, (COMPENSATE_SCREEN.ADDRESS + 1))
                    }
                }}
                onBack={() => {
                    onBack(COMPENSATE_SCREEN.ADDRESS - 1)
                }}
            />
        </>
    )
}