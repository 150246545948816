import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_FLIGHT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import axios from "axios";
import moment from "moment";
import Label from "../shared/Label";


export default function ManualFlight({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [flight, setFlight] = useState(compensate.flight)

    const [flightsList, setFlightsList] = useState(compensate.flightsList ?? []);
    const [flightsRecords, setflightsRecords] = useState(compensate.flightsRecords ?? []);

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail" />
            </div>
            <div className="form-content">
                <div className="box-message">
                    <FormattedMessage id="selectFlightFromList" />
                </div>

                <ListRadioOptions
                    items={flightsList}
                    onSelect={setFlight}
                    value={flight}
                />


            </div>
            <FormActionButton
                onNext={() => {
                    if (flight) {
                        onNext({
                            flight,
                            flightData: flightsRecords.find(data => data.flightLabel === flight) ?? null
                        }, (COMPENSATE_SCREEN.MANUAL_FLIGHT + 1))
                    }
                }}
                onBack={() => {
                    onBack(COMPENSATE_SCREEN.MANUAL_FLIGHT - 1)
                }}
            />
        </>
    )
}