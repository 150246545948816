import React from "react";
import { Link } from 'react-router-dom';

const ChooseClaim = () => {
    return (
        <div className="cc-wrapp">
            <div className="cc-tiles-wrapp gap-top cover no-repeat" style={{ backgroundImage: 'url("img/title-bg.png")' }}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-8 col-md-12 mx-auto text-center">
                            <h2 className="title">We champion the rights of people and help them to claim what is rightfully theirs</h2>
                        </div>
                        <div className="col-lg-12 cc-frame gap-top-sm">
                            <div>
                                <div>
                                    <div className="atc-icon mb-1">
                                        <img src="img/air-claim-icon-1.png" alt="" />
                                    </div>
                                    <div className="atc-title text-md fw-semibold">
                                        Flight cancelled?
                                    </div>
                                    <div className="atc-link mt-2 ps-2">
                                        <Link to="/fdc" className="fw-semibold o3">Start your Claim
                                            <img src="img/right-arrow-orange.png" className="ps-4" alt="" /></Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="atc-icon mb-1">
                                        <img src="img/air-claim-icon-2.png" alt="" />
                                    </div>
                                    <div className="atc-title text-md fw-semibold">
                                        Flight cancelled?
                                    </div>
                                    <div className="atc-link mt-2 ps-2">
                                        <Link to="/fdc" className="fw-semibold o3">Start your Claim
                                            <img src="img/right-arrow-orange.png" className="ps-4" alt="" /></Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="atc-icon mb-1">
                                        <img src="img/air-claim-icon-3.png" alt="" />
                                    </div>
                                    <div className="atc-title text-md fw-semibold">
                                        Flight cancelled?
                                    </div>
                                    <div className="atc-link mt-2 ps-2">
                                        <Link to="/fdc" className="fw-semibold o3">Start your Claim
                                            <img src="img/right-arrow-orange.png" className="ps-4" alt="" /></Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="atc-icon mb-1">
                                        <img src="img/air-claim-icon-4.png" alt="" />
                                    </div>
                                    <div className="atc-title text-md fw-semibold">
                                        Flight cancelled?
                                    </div>
                                    <div className="atc-link mt-2 ps-2">
                                        <Link to="/fdc" className="fw-semibold o3">Start your Claim
                                            <img src="img/right-arrow-orange.png" className="ps-4" alt="" /></Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="atc-icon mb-1">
                                        <img src="img/air-claim-icon-5.png" alt="" />
                                    </div>
                                    <div className="atc-title text-md fw-semibold">
                                        Flight cancelled?
                                    </div>
                                    <div className="atc-link mt-2 ps-2">
                                        <Link to="/fdc" className="fw-semibold o3">Start your Claim
                                            <img src="img/right-arrow-orange.png" className="ps-4" alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="cc-content-wrapp cover no-repeat gap-bottom" style={{ backgroundImage: 'url("img/content-bg.png")' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 mx-auto gap-top">
                            <h2 className="title">Why Choose Airtravel Claim?</h2>
                            <div className="row">
                                <div className="col-lg-5">
                                    <p className="p-md fw-semibold">These are some of the advantages of having Airtravel Claim pursue your compensation claim on your behalf.</p>
                                </div>
                                <div className="col-md-11 mx-auto gap-top">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul className="arrow-style-list mb-0 p-0 orange-icon w-of-li-100">
                                                <li>No Win – No Fee</li>
                                                <li>Simple online form to start your claim</li>
                                                <li>Straightforward process for your ease to claim</li>
                                                <li>Technology to confirm the validity of your claim</li>
                                                <li>Experienced specialists</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="arrow-style-list mb-0 p-0 orange-icon w-of-li-100">
                                                <li>Experienced specialists</li>
                                                <li>Submission of the letter of claim to the airline on your behalf and if proceedings are necessary:</li>
                                                <ul className="sub-list">
                                                    <li>Court fees are paid on your behalf</li>
                                                    <li>  Draft court proceedings are complete on your behalf</li>
                                                    <li>  Court representation is provided</li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChooseClaim;