import React from "react";
import Header from "./Header";
import Banner from "./Banner";
import ChooseClaim from "./ChooseClaim";
import CompensationThresholds from "./CompensationThresholds";
import Scenarios from "./Scenarios";
import Testimonial from "./Testimonial";
import ConatctBlock from "./ConatctBlock";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import FAQ from "./FAQ";

const Home = () => {
    return (
        <div className="home-wrapper">
            <Header />
            <Banner />
            <ChooseClaim />
            <HowItWorks />
            <CompensationThresholds />
            <div className="center-block cover no-repeat gap position-relative" style={{ backgroundImage: 'url("img/center-bg.png")' }}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <h2 className="title text-white">In the event of Extraordinary Circumstances, Airlines will not have to pay compensation</h2>
                            <p className="text-white fw-semibold gap-top-sm p-md">The definition of ‘extraordinary circumstances’ has been the subject of a lot of litigation in recent years with airlines looking to take advantage of the ambiguity. Airlines continue to find ways to argue over the definition of extraordinary circumstances, informing passengers that their delay is due to ‘manufacturing defects’ although, the delay was due to technical problems that should have resulted in passengers being compensated.</p>
                            <p className="text-white fw-semibold p-md"> We will challenge your airline to ensure you are rightfully compensated.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Scenarios />
            <FAQ />
            <ConatctBlock />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default Home;