import { Form } from 'react-bootstrap'
import './ListRadioOptions.css'

export default function ListRadioOptions({label, items, value, onSelect}){
    const Item = ({item}) => {
        return (
            <>
                <Form.Check
                    label={item.text}
                    type={'checkbox'}
                    value={item.value}
                    onChange={()=>{
                        onSelect(item.value)
                    }}
                    checked={item.value === value}
                />
            </>
        )
    }

    return (
        <div className="list-radio-options">
            <Form.Label className="label">{label}</Form.Label>
            {items.map((item) => <Item key={item.value} item={item}/>)}
        </div>
    )
}