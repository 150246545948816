import { cloneDeep } from "lodash";
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import Button_ from "../shared/Button";
import FormActionButton from "../shared/FormActionButton";
import ItemsRadioOptions from "../shared/ItemsRadioOptions";
import Label from "../shared/Label";
import Text from "../shared/Text";
import { isValidEmail, isValidMobile } from "../../utils/utils";

export default function OthersPassengers({compensate, onNext, onBack}){
    const intl = useIntl();

    const clientData = {
        number: 2,
        firstname: "",
        lastname: "",
        under18: true,
        bookedandpaid: false,
        email: "",
        mobile: "",
        availableForSignature: false,
        signature: ""
    }
    const [clients, setClients] = useState(compensate.clients ?? [cloneDeep(clientData)])
    const [whoBookedAndPaid, setWhoBookedAndPaid] = useState()

    const titleOptions = [
        {text: "Mr", value: "Mr"},
        {text: "Ms", value: "Ms"},
        {text: "Mx", value: "Mx"},
    ];

    const updateClient = (index, data) => {
        const clients_ = cloneDeep(clients);
        clients_[index] = {...clients_[index], ...data}
        setClients(clients_);

        const whoBookedAndPaid_ = clients_.findIndex(o => o.bookedandpaid === true);
        setWhoBookedAndPaid(whoBookedAndPaid_>-1 ? whoBookedAndPaid_ : undefined);
    }

    const addNewPassenger = () => {
        const clients_ = cloneDeep(clients);
        clients_.push({...cloneDeep(clientData), number: clients_.length + 2})
        setClients(clients_);
    }

    return (
        <>
            <div className="box-message">
                <FormattedMessage id="Every passengers compensation could be worth £XXX! Please input their details below."/>
            </div>
            <div className="form-content">

                {
                    clients.map((client, i) => {
                        return (
                            <>
                                <Label
                                    label={intl.formatMessage({id: "Passenger {number} Details"}, {number: client.number})} 
                                    style={{fontWeight: 'bold'}}
                                />

                                <Text 
                                    label={intl.formatMessage({id: "What is their first name?"})}
                                    onChange={(e)=>updateClient(i, {firstname: e.target.value})}
                                    value={client.firstName}
                                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                                />

                                <Text 
                                    label={intl.formatMessage({id: "What is their surname?"})}
                                    onChange={(e)=>updateClient(i, {lastname: e.target.value})}
                                    value={client.lastname}
                                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                                />

                                <ItemsRadioOptions
                                    label={intl.formatMessage({id: "Is this person under 18?"})}
                                    items={[{text: "Yes", value: true}, {text: "No", value: false}]}
                                    onSelect={(val)=>{
                                        const data = {
                                            under18: val
                                        }
                                        if(!val){
                                            data.bookedandpaid = false;
                                            data.email = '';
                                            data.mobile = '';
                                            data.availableForSignature = false;
                                            data.signature = '';
                                        }
                                        updateClient(i, data);
                                    }}
                                    value={client.under18}
                                    style={{marginLeft: 0, marginRight: 0}}
                                    topStyle={{textAlign: "Left"}}
                                />

                                {
                                    !client.under18 &&

                                    <>
                                        {
                                            (
                                                compensate.didYouBook !== "Yes" &&
                                                (whoBookedAndPaid === undefined || whoBookedAndPaid===i)
                                            ) && 
                                            <ItemsRadioOptions
                                                label={intl.formatMessage({id: "Did this person book and pay for the fight?"})}
                                                items={[{text: "Yes", value: true}, {text: "No", value: false}]}
                                                onSelect={(val)=>{
                                                    const data = {
                                                        bookedandpaid: val
                                                    }
                                                    updateClient(i, data);
                                                }}
                                                value={client.bookedandpaid}
                                                style={{marginLeft: 0, marginRight: 0}}
                                                topStyle={{textAlign: "Left"}}
                                            />
                                        }

                                        <Text 
                                            label={intl.formatMessage({id: "What is their email address?"})}
                                            onChange={(e)=>updateClient(i, {email: e.target.value})}
                                            value={client.email}
                                            style={{border: 'none', borderBottom: '1px solid #707070'}}
                                        />

                                        <Text 
                                            label={intl.formatMessage({id: "What is their mobile number?"})}
                                            onChange={(e)=>updateClient(i, {mobile: e.target.value})}
                                            value={client.mobile}
                                            style={{border: 'none', borderBottom: '1px solid #707070'}}
                                        />

                                        {
                                            whoBookedAndPaid===i &&
                                            <ItemsRadioOptions
                                                label={intl.formatMessage({id: "Is this person available with you now to sign?"})}
                                                items={[{text: "Yes", value: true}, {text: "No", value: false}]}
                                                onSelect={(val)=>{
                                                    const data = {
                                                        availableForSignature: val
                                                    }
                                                    updateClient(i, data);
                                                }}
                                                value={client.availableForSignature}
                                                style={{marginLeft: 0, marginRight: 0}}
                                                topStyle={{textAlign: "Left"}}
                                            />
                                        }
                                    </>
                                }

                            </>
                        )
                    })
                }

                <Button_
                    label={intl.formatMessage({id: "+ Add Another Passenger"})} 
                    onClick={addNewPassenger}
                />
            </div>
            <FormActionButton
                onNext={()=>{

                    if(whoBookedAndPaid !== undefined){
                        const client = clients[whoBookedAndPaid];
                        if(!client.email && !client.mobile){
                            alert("One of email or mobile is mandatory for lead passenger")
                            return;
                        }

                        if(client.email && !isValidEmail(client.email)){
                            alert("Please enter valid email address")
                            return;
                        }else if(client.mobile && !isValidMobile(client.mobile)){
                            alert("Please enter valid mobile number")
                            return;
                        }
                    }
                    if(
                        clients.every(o => o.firstname && o.lastname)
                    ){
                        onNext({
                            clients
                        }, (COMPENSATE_SCREEN.OTHER_PASSENGERS+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.OTHER_PASSENGERS-1)
                }}
            />
        </>
    )
}