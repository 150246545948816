import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

const Banner = () => {
    return (
        <div className="home-banner-wrapp cover no-repeat" style={{ backgroundImage: 'url("img/banner-bg.png")' }}>
            <div className="container d-flex align-items-end gap-top">
                <div className="row">
                    <div className="col-lg-12 col-md-12 h-banner-cnt">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="title">Flight delayed or Flight cancelled?</h1>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="p-md fw-semibold">If you have suffered a flight delay, had your flight cancelled within 14 days of departure, had your flight re-routed, or you were denied boarding, you may be able to claim up to £520 flight compensation. Airlines have been cancelling thousands of flights. Have you been affected? We can help you get compensation!</p>
                                    </div>
                                </div>
                                <div className="form-btn gap-top">
                                    <Link to="/fdc" className="btn-w black">Start your claim</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 mx-auto banner-frame mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="frame-block">
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src="img/b-icon-1.png" alt="" />
                                        <div className="ft">Trusted <br /> by Millions</div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-block">
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src="img/b-icon-2.png" alt="" />
                                        <div className="ft">Claim Up to £600 Compensation</div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-block">
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src="img/b-icon-3.png" alt="" />
                                        <div className="ft">No matter the ticket price.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                        <Link to="/home" className="theme-down-arrow">
                        <img src="img/down-animation-arrow.png" alt="" />
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;