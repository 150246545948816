import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_ALL_AIRPORT_ENDPOINT, SEARCH_AIRPORT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import Label from "../shared/Label";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { isEmpty } from "lodash";

export default function CheckFlightDetails({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [departureAirport, setDepartureAirport] = useState(compensate.departureAirport)
    const [destinationAirport, setDestinationAirport] = useState(compensate.destinationAirport)

    const fetchAirportsData = async (search) => {
        const formatAirports = (data) => data?.map(airport => ({ label: airport.airportName, value: airport.airportCode }));
        if (isEmpty(search)) {
            return axios.get(GET_ALL_AIRPORT_ENDPOINT).then(data => formatAirports(data.data.data)).catch(_ => []);
        } else {
            return axios.post(SEARCH_AIRPORT_ENDPOINT, { "destination": search }).then(data => formatAirports(data.data.data)).catch(_ => []);
        }
    }

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail" />
            </div>
            <div className="form-content">
                <Label label={intl.formatMessage({ id: "departureAirport" })} />
                <AsyncSelect
                    value={departureAirport}
                    loadOptions={fetchAirportsData}
                    onChange={selectedOption => setDepartureAirport(selectedOption)}
                />

                <Label label={intl.formatMessage({ id: "desinationAirport" })} />
                <AsyncSelect
                    value={destinationAirport}
                    loadOptions={fetchAirportsData}
                    onChange={selectedOption => setDestinationAirport(selectedOption)}
                />
            </div>
            <FormActionButton
                onNext={() => {
                    if (departureAirport && destinationAirport) {
                        onNext({
                            departureAirport,
                            destinationAirport
                        }, (COMPENSATE_SCREEN.START_DESTINATION + 1))
                    }
                }}
                onBack={() => {
                    onBack(COMPENSATE_SCREEN.START_DESTINATION - 1)
                }}
            />
        </>
    )
}