import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
    const Tell = ({ number, ...props }) => {
        return (
            <a href={`tel:${number}`}>
                {props.children}
            </a>
        );
    }

    const Mailto = ({ email, ...props }) => {
        return (
            <a href={`mailto:${email}`}>
                {props.children}
            </a>
        );
    }
    return (
        <footer className="footer-wrapp gap text-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-8">
                                <Link to="/home">
                                    <img src="img/white-logo.png" alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mt-sm-30">
                        <div className="row gx-5">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="title">Useful Links</h4>
                                        <ul>
                                            <li><Link to="/home">Home</Link></li>
                                            <li><Link to="/faq-page">FAQ’s</Link></li>
                                            <li><Link to="/home">Contacts</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 mt-sm-30">
                                        <h4 className="title">Policies</h4>
                                        <ul>
                                            <li><Link to="/policy">Privacy Policy</Link></li>
                                            <li><Link to="/complaints-procedures">Complaints Procedures</Link></li>
                                            <li><Link to="/terms-of-use">Terms & Conditions</Link></li>
                                            <li><Link to="/home">Cookies Policy</Link></li>
                                            <li><Link to="/terms-of-use">Terms Of Use</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 footer-address mt-sm-30">
                                <h4 className="title">Get in Touch</h4>
                                <ul>
                                <li>
                                        <Tell number="0330 808 8636">
                                           <img src="img/call-white.svg" alt="" className="me-2" /> 0330 808 8636
                                        </Tell>
                                    </li>
                                    <li>
                                        <Mailto email="info@airtravelclaim.com">
                                        <img src="img/mail-white.png" alt="" className="me-2" /> info@airtravelclaim.com
                                        </Mailto>
                                    </li>
                                    <li><Link to="/Home"><img src="img/map-white.svg" alt="" className="me-2" /> Air Travel Claim. 84 Salop Street Wolverhampton WV3 0SR.</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fb">
                    <div className="row align-items-center">
                        <div className="col-md-4 footer-s-links">
                            <div>
                                <Link to="/home"><img src="img/instagram.svg" alt="" /></Link>
                                <Link to="/home"><img src="img/facebook.svg" alt="" /></Link>
                                <Link to="/home"><img src="img/linkedin.svg" alt="" /></Link>
                                <Link to="/home"><img src="img/twitter.svg" alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;