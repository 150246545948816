import { Button } from "react-bootstrap";
import './FormActionButton.css'

export default function FormActionButton({onBack, onNext, onSubmit, backTitle='Back', nextTitle='Next', submitTitle='Submit'}){

    return (
        <div className="action-button">
            {onBack && <Button onClick={onBack}>{backTitle}</Button>}
            {onNext && <Button onClick={onNext}>{nextTitle}</Button>}
            {onSubmit && <Button onClick={onSubmit}>{submitTitle}</Button>}
        </div>
    )
}