import { Form } from "react-bootstrap";
import './Select.css'

export default function Select({label, placeholder, options=[], value, onChange, style}){
    return (
        <div className="custom-dropdown">
            <Form.Label className="label">{label}</Form.Label>
            <Form.Select
                defaultValue={value}
                onChange={(e)=>{
                    onChange(e.target.value)
                }}
                style={style}
            >
                {
                    options.map(item => <option key={item.value} value={item.value}>{item.text}</option>)
                }
            </Form.Select>
        </div>
    )
}