import { useDispatch, useSelector } from 'react-redux';
import End from '../../components/compensate-forms/End';
import CheckFlightDetails from '../../components/compensate-forms/CheckFlightDetails';
import NumberOfPassengers from '../../components/compensate-forms/NumberOfPassengers';
import {
  COMPENSATE_SCREEN,
  COMPENSATION_SUBMIT_ENDPOINT,
  CRM_SUBMIT_AUTH,
  CRM_SUBMIT_ENDPOINT
} from '../../constants';
import PageLayout from '../../layouts/PageLayout';
import {
  setCompensateState,
  setCompensateStep
} from '../../store/actions/Compensate';
import './FlightCompensation.css';
import DepartureDate from '../../components/compensate-forms/DepartureDate';
import ManualFlight from '../../components/compensate-forms/ManualFlight';
import AirLinesDetail from '../../components/compensate-forms/AirLinesDetail';
import DelayReason1 from '../../components/compensate-forms/DelayReason1';
import DelayReason2 from '../../components/compensate-forms/DelayReason2';
import DelayReason3 from '../../components/compensate-forms/DelayReason3';
import Passengers from '../../components/compensate-forms/Passengers';
import Address from '../../components/compensate-forms/Address';
import ContactDetails from '../../components/compensate-forms/ContactDetails';
import Signature from '../../components/compensate-forms/Signature';
import OtherComment from '../../components/compensate-forms/OtherComment';
import Evidence from '../../components/compensate-forms/Evidence';
import { useState } from 'react';
import axios from 'axios';
import { getCompensationPayload, getCompensationPayloadForCRM } from './mapper';
import OthersPassengers from '../../components/compensate-forms/OthersPassengers';

export default function FlightCompensation() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { compensateStep, compensate } = useSelector(
    (state) => state.compensate
  );

  const onBack = (step) => {
    dispatch(setCompensateStep(step));
  };

  const onNext = (data, step) => {
    dispatch(setCompensateStep(step));
    dispatch(
      setCompensateState({
        ...compensate,
        ...data
      })
    );
  };

  const onSubmit = (data, step) => {
    setIsSubmitted(true);
    const finalData = {
      ...compensate,
      ...data
    };
    dispatch(setCompensateState(finalData));

    Promise.allSettled([
      //mock entry
      axios.post(
        COMPENSATION_SUBMIT_ENDPOINT,
        getCompensationPayload(finalData)
      ),
      //CRM entry
      axios.post(CRM_SUBMIT_ENDPOINT, getCompensationPayloadForCRM(finalData), {
        headers: {
          //Authorization: CRM_SUBMIT_AUTH,
          'Content-Type': 'application/json'
        }
      })
    ])
      .then((e) => {
        dispatch(setCompensateStep(step));
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const renderStepForm = () => {
    switch (compensateStep) {
      case COMPENSATE_SCREEN.NUMBER_OF_PASSENGERS:
        return <NumberOfPassengers onNext={onNext} compensate={compensate} />;
      case COMPENSATE_SCREEN.START_DESTINATION:
        return (
          <CheckFlightDetails
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.DEPARTURE_DATE:
        return (
          <DepartureDate
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.MANUAL_FLIGHT:
        return (
          <ManualFlight
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.AIRLINES_DETAIL:
        return (
          <AirLinesDetail
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.DELAY_REASON_1:
        return (
          <DelayReason1
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      // case COMPENSATE_SCREEN.DELAY_REASON_2:
      //     return <DelayReason2 onBack={onBack} onNext={onNext} compensate={compensate}/>;
      case COMPENSATE_SCREEN.DELAY_REASON_3:
        return (
          <DelayReason3
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.PASSENGERS:
        return (
          <Passengers onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.ADDRESS:
        return (
          <Address onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.CONTACT_DETAILS:
        return (
          <ContactDetails
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.OTHER_PASSENGERS:
        return (
          <OthersPassengers
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.SIGNATURE:
        return (
          <Signature onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.EVIDENCE:
        return (
          <Evidence onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.OTHER_COMMENTS:
        return (
          <OtherComment
            onBack={onBack}
            isSubmitted={isSubmitted}
            onSubmit={onSubmit}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.END:
        //return <></>;
        return <End />;
      default:
        return <></>;
    }
  };

  return (
    <PageLayout>
      <div className='form-container'>{renderStepForm()}</div>
    </PageLayout>
  );
}
