import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_ALL_AIRLINES_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import DatePicker from "../shared/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setAirlines } from "../../store/actions/Compensate";
import Text from "../shared/Text";
import Label from "../shared/Label";
import Select from 'react-select';
import axios from "axios";

export default function AirLinesDetail({ compensate, onNext, onBack }) {
    const intl = useIntl();
    const [airline, setAirline] = useState(compensate.airline ?? compensate.flightData?.airline)
    const [flightNumber, setFlightNumber] = useState(compensate.flightNumber ?? compensate.flightData?.flightNumber)
    const [departureDate, setDepartureDate] = useState(compensate.departureDate)
    const { airlines } = useSelector(state => state.compensate)
    const dispatch = useDispatch();

    useEffect(() => {
        fetchAirlines();
    }, [])

    const fetchAirlines = () => {
        const formatAirlines = data => data?.map(airline => ({ label: airline.airlineName, value: airline.iata }));
        axios.get(GET_ALL_AIRLINES_ENDPOINT).then(data => formatAirlines(data.data.data)).catch(_ => []).then(data => dispatch(setAirlines(data)));
    }

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail" />
            </div>
            <div className="form-content">
                <Label label={intl.formatMessage({ id: "airlineYouFlyWith" })} />
                <Select
                    value={airline}
                    options={airlines}
                    onChange={selectedOption => {
                        setAirline(selectedOption)
                    }}
                />
                <Text
                    label={intl.formatMessage({ id: "enterFlightNumber" })}
                    onChange={(e) => setFlightNumber(e.target.value)}
                    value={flightNumber}
                />
                <DatePicker
                    label={intl.formatMessage({ id: "whatIsScheduleDeparture" })}
                    onChange={(e) => setDepartureDate(e.target.value)}
                    value={departureDate}
                />
            </div>
            <FormActionButton
                onNext={() => {
                    if (airline && flightNumber && departureDate) {
                        onNext({
                            airline,
                            flightNumber,
                            departureDate,
                        }, (COMPENSATE_SCREEN.AIRLINES_DETAIL + 1))
                    }
                }}
                onBack={() => {
                    onBack(COMPENSATE_SCREEN.AIRLINES_DETAIL - (compensate.flightsList?.length ? 1 : 2))
                }}
            />
        </>
    )
}