import './PageLayout.css';

export default function PageLayout(props){
    return (
        <div className="layout">
            <div className="header"></div>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}