import React from "react";

const HowItWorks = () => {
    return (
        <div className="hiw-wrapp gap">
            <div className="container">
                <div className="text-center">
                    <h2 class="title">How it works</h2>
                </div>
                <div className="fdc-stepper-wrapp gap-top-sm">
                    <div className="stepper-block active filled-after">
                        <div className="sb-image-block">
                            1
                        </div>
                    </div>
                    <div className="stepper-block active filled-before filled-after">
                        <div className="sb-image-block">
                            2
                        </div>
                    </div>
                    <div className="stepper-block active filled-before">
                        <div className="sb-image-block">
                            3
                        </div>
                    </div>
                </div>
                <div className="row hiw-tiles">
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-1.svg" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">Complete our form</div>
                                <p>
                                    Complete our online flight compensation form. Ensure you have your flight details ready before you get started, such as your arrival and departure airports and date of flight. You can also claim for everyone in your party who travelled on your booking.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-2.svg" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">We will asses your claim</div>
                                <p>
                                    Our team at Airtravel Claim shall begin the process of verifying your claim. If your claim is valid, your case will be referred to our law firm partner My Law Matters who will start the process of getting your compensation from the airline and keep you informed throughout the process.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-3.png" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">Payment is made</div>
                                <p>
                                    Once the airline has established that they will be paying out your claim, we will send the settlement directly to you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dividwr"></div>
                <div className="row hiw-tiles">
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-4.png" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">The Verdict</div>
                                <p>
                                    The EU Air Passenger Rights Regulation 261/2004 means that passengers such as yourself are entitled to compensation for disrupted flights.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-5.png" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">The Challange</div>
                                <p>
                                    Claiming by yourself can be a stressful experience due to the complexity sometimes encountered. There are numerous cases where airlines do not respond, or they try to convince you that the claim is invalid. By using Airtravel Claim, we will take care of all the hard work in challenging the airline.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="hiw-tiles-image-block">
                                <img src="img/hiw-6.png" alt="" />
                            </div>
                            <div className="hiw-tiles-content">
                                <div className="text-md o3 fw-bold mb-1">The Solution</div>
                                <p>
                                    Airtravel Claim will handle everything for you, we can easily check if you are eligible to claim compensation on a NO WIN – NO FEE basis.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;