import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import TextArea from "../shared/TextArea";

export default function OtherComment({onBack, onSubmit, compensate, isSubmitted}){
    const [comment, setComment] = useState(compensate.comment)
    const intl = useIntl();

    return (
        <>
            <div className="heading">
                <FormattedMessage id="Feel free to share any other information about your claim"/>
            </div>
            <div className="form-content">
                <TextArea
                    placeholder={intl.formatMessage({id: "Space for free text narrative comments"})}
                    onChange={(e)=>setComment(e.target.value)}
                    value={comment}
                    style={{width: '100%'}}
                    rows={'6'}
                />
            </div>
            
            <FormActionButton
                onSubmit={()=>{
                    if(!isSubmitted && comment){
                        onSubmit({
                            comment
                        }, (COMPENSATE_SCREEN.OTHER_COMMENTS+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.OTHER_COMMENTS-1)
                }}
                submitTitle={intl.formatMessage({id: "Submit Your Claim"})}
            />
        </>
    )
}