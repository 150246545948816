import React from "react";
import { Link } from "react-router-dom";

const CompensationThresholds = () => {
    return (
        <div className="dt-wrapp" >
            <div className="gap-top cover no-repeat gb" style={{ backgroundImage: 'url("img/fct-bg.png")' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <h2 className="title">Flight Compensation Thresholds</h2>
                            <div className="fw-semibold">(based on a party of 2)</div>
                        </div>
                    </div>
                    <div className="gap-top-sm">
                        <div className="mb-3">
                            <img src="img/fct-1.png" alt="" />
                            <span className="fw-semibold ms-3 d-inline-block min-width-block">1500km or less</span>
                            <span className="fw-semibold ms-3 d-inline-block">= Up to £ 440</span>
                        </div>
                        <div className="mb-3">
                            <img src="img/fct-1.png" alt="" />
                            <span className="fw-semibold ms-3 d-inline-block min-width-block">1500km to 3500km</span>
                            <span className="fw-semibold ms-3 d-inline-block">= Up to £ 700</span>
                        </div>
                        <div>
                            <img src="img/fct-1.png" alt="" />
                            <span className="fw-semibold ms-3 d-inline-block min-width-block">3500km or more </span>
                            <span className="fw-semibold ms-3 d-inline-block">= Up to £ 1,040</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gap cover no-repeat fct-2-wrapp" style={{ backgroundImage: 'url("img/fct-2-bg.jpg")' }}>
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-md-4 fct-2-img-block">
                            <img src="img/fct-2-1.jpg" alt="" />
                        </div>
                        <div className="col-md-8">
                            <div className="dt-table">
                                <div className="p-4">
                                    <div className="table-responsive">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Flight Distance</th>
                                                    <th>Length of Delay</th>
                                                    <th>Compensation</th>
                                                </tr>
                                                <tr>
                                                    <td>1500 km or Less</td>
                                                    <td>£ 440</td>
                                                    <td>£ 440</td>
                                                </tr>
                                                <tr>
                                                    <td>1500km - 3500km</td>
                                                    <td>£ 700</td>
                                                    <td>£ 700</td>
                                                </tr>
                                                <tr>
                                                    <td>3500km or more</td>
                                                    <td>£ 1,040</td>
                                                    <td>£ 1,040</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="gap-top-sm gap-bottom-sm text-end">
                                        <a class="btn-w color3" href="/fdc">Start you claim</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CompensationThresholds;