import { Button, Form } from 'react-bootstrap'
import './ItemsRadioOptions.css'

export default function ItemsRadioOptions({label, items, value, onSelect, style, topStyle}){
    const ItemBlock = ({item}) => {
        return (
            <Button onClick={() => {
                onSelect(item.value)
            }} className={value === item.value && 'selected'} style={style}>{item.text}</Button>
        )
    }

    return (
        <div className="items-radio-options" style={topStyle}>
            <Form.Label className="label">{label}</Form.Label>
            {items.map((item, index) => <span key={item.text}><ItemBlock item={item}/>{(index+1)%5 === 0 && <br/>}</span>)}
        </div>
    )
}