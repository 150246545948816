import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Congratulations = () => {
    return (
        <div className="congratulation-wrapp">
            <Header bg='#ffffff' />
            <div className="gb gap position-relative">
                <div className="container">
                    <div className="fdc-form-wrapp">
                        <div className="fdc-shadow-block">
                            <div className="fdc-form congratulation-block text-center">
                                <h2 className="title">Congratulations</h2>
                                <div className="text-md cong-content-wrapp fw-semibold">
                                    <div>
                                        Your submission, has been uploaded <br />
                                        <span className="theme-color-1">Ticket no: ATC78925933</span> all attachments a copy shared to your mail <br />
                                        extended with ********rty@gmail.com
                                    </div>
                                    <div className="mt-3">Our Team will contact you shortly</div>
                                </div>
                                <div className="text-center mt-4"><Link to="/home" className="btn-w">Continue to website</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle md orange left center"></div>
            <div class="circle md blue right"></div>
            <Footer />
        </div>
    )
}

export default Congratulations;