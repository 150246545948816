import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { COMPENSATE_SCREEN, EACH_PASSENGER_CLAIM } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ItemsRadioOptions from "../shared/ItemsRadioOptions";

export default function NumberOfPassengers({onNext, compensate}){
    const [passengerCountInParty, setPassengerCountInParty] = useState(compensate.passengerCountInParty)

    const passengerCountInPartyOptions = [
        {text: "1", value: "1"},
        {text: "2", value: "2"},
        {text: "3", value: "3"},
        {text: "4", value: "4"},
        {text: "5", value: "5"},
        {text: "6", value: "6"},
        {text: "7", value: "7"},
        {text: "8", value: "8"},
        {text: "9", value: "9"},
        {text: "10+", value: "10+"},
    ];

    const claimWorth = () => passengerCountInParty.match(/\d+/g) * EACH_PASSENGER_CLAIM;

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkCompensate"/>
            </div>
            <div className="form-content">
                <div className="box-message">
                    <FormattedMessage id="peopleWereInParty"/>
                </div>

                <ItemsRadioOptions
                    items={passengerCountInPartyOptions}
                    onSelect={setPassengerCountInParty}
                    value={passengerCountInParty}
                />
            </div>
            
            {
                passengerCountInParty &&
                <div>
                    <div className="box-message">
                        <FormattedMessage id="claimWorth" values={{claimWorth: claimWorth()}}/>
                    </div>
                    <FormActionButton
                        onNext={()=>{
                            onNext({
                                passengerCountInParty
                            }, (COMPENSATE_SCREEN.NUMBER_OF_PASSENGERS+1))
                        }}
                    />
                </div>
            }

            <div className="info">
                <FormattedMessage id="checkCompensateFree"/>
            </div>
        </>
    )
}