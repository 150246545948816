import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const CompleteDetail = () => {
    return (
        <div className="fdc-wrapp">
            <Header bg='#ffffff' />
            <div className="gb gap position-relative">
                <div className="container">
                    <div className="fdc-form-wrapp">
                        <div className="fdc-stepper-wrapp">
                            <div className="stepper-block active filled-after">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-1.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Enter your details</div>
                            </div>
                            <div className="stepper-block active filled-before">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-2.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Complete your details</div>
                            </div>
                            <div className="stepper-block">
                                <div className="sb-image-block">
                                    <img src="img/f-stepper-3.png" alt="" />
                                </div>
                                <div className="fdc-stpper-title fw-semibold theme-color-1">Your Confirmation</div>
                            </div>
                        </div>
                        <div className="fdc-shadow-block">
                            <div className="fdc-title-block text-center">
                                <h2 className="title">About you</h2>
                            </div>
                            <div className="fdc-form gif-input">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-block">
                                            <label>Title</label>
                                            <div className="select-wrapper">
                                                <select>
                                                    <option value="0">Mr.</option>
                                                    <option value="1">Miss.</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>First Name</label>
                                            <input type="text" placeholder="Enter your First name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Last Name</label>
                                            <input type="text" placeholder="Enter your First name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-block">
                                            <label>Full Names of other passengers in your flight booking</label>
                                            <textarea placeholder=""></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Departure Date</label>
                                            <input type="date" placeholder="dd mm yyyy" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Mobile Number</label>
                                            <input type="text" placeholder="(000)-00-0000" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Email Id</label>
                                            <input type="text" placeholder="Enter your email id" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Land line Number</label>
                                            <input type="text" placeholder="(000)-00-0000" />
                                        </div>
                                    </div>
                                </div>


                                <div className="row align-items-end">
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Address</label>
                                            <input type="text" placeholder="Line 1" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label></label>
                                            <input type="text" placeholder="Line 2" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label></label>
                                            <input type="text" placeholder="City" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label></label>
                                            <input type="text" placeholder="State" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label></label>
                                            <input type="text" placeholder="Pincode" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <label>Would you like to add more flights?</label>
                                            <div className="select-wrapper">
                                                <select>
                                                    <option value="0">Select</option>
                                                    <option value="1">Option 1</option>
                                                    <option value="2">Option 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mx-auto">
                                        <div className="row gx-2">
                                            <div className="col-md-6 text-center mx-auto mt-4">
                                                <Link to="/fdc" className="btn-w black w-100">Back</Link>
                                            </div>
                                            <div className="col-md-6 text-center mx-auto mt-4">
                                                <Link to="/your-confirmation" className="btn-w color3 w-100">Next</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle md orange left center"></div>
            <div class="circle md blue right"></div>
            <Footer />
        </div>
    )
}

export default CompleteDetail;