import { COMPENSATE_SCREEN } from '../../constants';
import {
	AIRLINES, AIRPORT, COMPENSATE_STATE, COMPENSATE_STEP
} from '../constants';

export const initCompensateState = {
  airlines: [],
  airports: [],
  compensate: {},
  compensateStep: COMPENSATE_SCREEN.NUMBER_OF_PASSENGERS
}

const compensate = (state = initCompensateState, {type, data}) => {
	switch (type) {
		case AIRLINES:
			return {
				...state,
				airlines: data
			}
		case AIRPORT:
			return {
				...state,
				airports: data,
			}
		case COMPENSATE_STATE:
			return {
				...state,
				compensate: data,
			}
		case COMPENSATE_STEP:
			return {
				...state,
				compensateStep: data,
			}
		default:
			return state;
	}
}

export default compensate