import moment from 'moment/moment';

export function getCompensationPayload(compensation) {
  const clients = [
    {
      title: compensation.title,
      firstname: compensation.firstName,
      lastname: compensation.surname,
      dateofbirth: moment(compensation.dob).format('DD/MM/YYYY'),
      bookedandpaid: compensation.didYouBook === 'Yes',
      email: compensation.emailAddress,
      mobile: compensation.mobileNumber,
      landline: compensation.landline,
      address: {
        line1: compensation.address,
        line2: compensation.addressLine2,
        city: compensation.city,
        postcode: compensation.postcode,
        county: compensation.county,
        country: compensation.country
      },
      signature: compensation.signature
    }
  ];

  if (compensation.clients?.length) {
    compensation.clients.forEach((client) => {
      clients.push({
        firstname: client.firstname,
        lastname: client.lastname,
        under18: client.under18,
        bookedandpaid: client.bookedandpaid,
        email: client.email,
        mobile: client.mobile,
        signature: client.signature
      });
    });
  }
  return {
    web_form_id: 'flightdelays_milestone1',
    departureairport: compensation.departureAirport,
    arrivalairport: compensation.destinationAirport,
    departuredate: moment(compensation.departureDate).format('DD/MM/YYYY'),
    airline: compensation.airline,
    flightnumber: compensation.flightNumber,
    flightdelayreason: compensation.delayReason3,
    fightdelay: compensation.howManyHoursLate,
    airlinereason: compensation.causedTheDelay,
    clients: clients,
    boardingpass: compensation.evidence,
    boardingpassfiletype: compensation.evidenceMime,
    comments: compensation.comment
  };
}

export function getCompensationPayloadForCRM(compensation) {
  const clients = [
    {
      title: compensation.title,
      firstname: compensation.firstName,
      lastname: compensation.surname,
      dob: moment(compensation.dob).format('DD/MM/YYYY'),
      email: {
        primary: compensation.emailAddress
      },
      phone: {
        primary: compensation.mobileNumber,
        secondary: compensation.landline
      },
      address: {
        line1: compensation.address,
        city: compensation.city,
        postcode: compensation.postcode,
        line2: compensation.addressLine2,
        county: compensation.county,
        country: compensation.country
      },
      clientdata: {
        bookingAndPayment: compensation.didYouBook === 'Yes',
        clientSignature: compensation.signature?.split('base64,')?.[1],
        boardingPass: {
          filename: compensation.evidenceMime,
          value: compensation.evidence?.split('base64,')?.[1]
        }
      }
    }
  ];

  if (compensation.clients?.length) {
    compensation.clients.forEach((client) => {
      clients.push({
        firstname: client.firstname,
        lastname: client.lastname,
        email: {
          primary: client.email
        },
        phone: {
          primary: client.mobile
        },
        clientdata: {
          bookingAndPayment: client.bookedandpaid,
          clientSignature: client.signature?.split('base64,')?.[1],
          agedUnder18: client.under18
        }
      });
    });
  }
  return {
    payload: {
      clients: clients,
      type: 'Flight Delay',
      campaignCode: 'ATCWEBSITE',
      state: 'Claim Received',
      defendant: 'BA CityFlyer',
      casedata: {
        brand: 'Air Travel Claim',
        // "flightClaimReason": compensation.delayReason3,
        flightNumber: compensation.flightNumber,
        // "airlineReasonForDelay": compensation.causedTheDelay,
        // "airlineCauseForCancellation": compensation.causedTheDelay,
        // "howLateWasYourFlight": compensation.howManyHoursLate,
        cancellationNotice: compensation.whenInformedFlightCancelled,
        // "deniedBoardingReason": compensation.causedTheDelay,
        departureAirport: compensation.departureAirport.label,
        departureAirportCode: compensation.departureAirport.value,
        arrivalAirport: compensation.destinationAirport.label,
        arrivalAirportCode: compensation.destinationAirport.value,
        scheduledDateOfDeparture: moment(compensation.departureDate).format(
          'DD/MM/YYYY'
        ),
        offeredAnAlternateFlight:
          compensation.wereOfferedAlternativeFlight === 'Yes',
        alternateFlightNumber: compensation.alternativeFlightNumber,
        checkInPresentForBoarding: compensation.checkInForBoarding === 'YES',
        clientsAdditionalInformation: compensation.comment
        // "scheduledDepartureTime": "xxxxxx",
        // "scheduledDateOfArrival": "01/01/2001",
        // "scheduledArrivalTime": "xxxxxx",
        // "totalMinutesDelayedBy": "xxxxxx",
      }
    }
  };
}
