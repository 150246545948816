import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";

export default function DelayReason1({compensate, onNext, onBack}){
    const [delayReason3, setDelayReason3] = useState(compensate.delayReason3)
    const intl = useIntl();

    const delayReasons = [
        {text: "My fight was delayed.", value: "My fight was delayed."},
        {text: "My fight was cancelled", value: "My fight was cancelled"},
        {text: "I was denied boarding", value: "I was denied boarding"},
        {text: "My fight was rerouted", value: "My fight was rerouted"},
        {text: "My fight was returned", value: "My fight was returned"},
    ];

    return (
        <>
            <div className="heading">
                <FormattedMessage id="checkFlightDetail"/>
            </div>
            <div className="form-content">

                <ListRadioOptions
                    label={intl.formatMessage({id: "whyFlightDelayed"})}
                    items={delayReasons}
                    onSelect={setDelayReason3}
                    value={delayReason3}
                />
            </div>
            <FormActionButton
                onNext={()=>{
                    if(delayReason3){
                        onNext({
                            delayReason3,
                        }, (COMPENSATE_SCREEN.DELAY_REASON_1+1))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.DELAY_REASON_1-1)
                }}
            />
        </>
    )
}