import React from "react";
import Header from "./Header";
import Testimonial from "./Testimonial";
import ConatctBlock from "./ConatctBlock";
import Footer from "./Footer";
import InnerBanner from "./InnerBanner";
import FAQ from "./FAQ";

const FaqPage = () => {
    return(
        <div className="faq-page-wrapp">
            <Header bg='#EAEAEA'/>
            <InnerBanner pageName="FAQ" />
            <FAQ />
            <ConatctBlock />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default FaqPage;