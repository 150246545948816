import { Button } from "react-bootstrap";
import './Button.css'

export default function Button_({onClick, label}){

    return (
        <div className="custom-button">
            <Button onClick={onClick}>{label}</Button>
        </div>
    )
}