import './App.css';
import { Provider } from 'react-redux';
import { createMemoryHistory } from 'history';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from './store';
import FlightCompensation from './containers/FlightCompensation';
import { IntlProvider } from 'react-intl';
import useLocale from './hooks/useLocale';
import Home from './components/webiste-components/Home';
import FAQ from './components/webiste-components/FAQ';
import AboutUs from './components/webiste-components/AboutUs';
import Solutions from './components/webiste-components/Solutions';
import HowItWorks from './components/webiste-components/HowItWorks';
import ViewClaims from './components/webiste-components/ViewClaims';
import FlightDelayClaim from './components/webiste-components/FlightDelayClaim';
import CompleteDetail from './components/webiste-components/CompleteDetail';
import YourConfirmation from './components/webiste-components/YourConfirmation';
import Congratulations from './components/webiste-components/Congratulations';
import ComplaintsProcedures from './components/webiste-components/ComplaintsProcedures';
import Policy from './components/webiste-components/Policy';
import TermsOfUse from './components/webiste-components/TermsOfUse';
import FaqPage from './components/webiste-components/FAQ-Page';
import ConatctPage from './components/webiste-components/ConatctPage';

const history = createMemoryHistory();

function App() {
  const {locale, message, lang} = useLocale();
  return (
    <Provider store={store}>
      <IntlProvider messages={message} locale={locale} defaultLocale={lang} onError={()=>{}}>
        <BrowserRouter location={history.location} navigator={history}>
          <Routes>
            <Route path="/" element={<FlightCompensation/>} />
            <Route path="/home" element={<Home />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/faq-page" element={<FaqPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/view-claims" element={<ViewClaims />} />
            <Route path="/fdc" element={<FlightDelayClaim />} />
            <Route path='/complete-detail' element={<CompleteDetail />} />
            <Route path='/your-confirmation' element={<YourConfirmation />} />
            <Route path='/congratulations' element={<Congratulations />} />
            <Route path='/complaints-procedures' element={<ComplaintsProcedures />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/conatct-page' element={<ConatctPage />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  );
}

export default App;
