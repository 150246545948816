import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN } from "../../constants";
import { isValidEmail, isValidLandline, isValidMobile } from "../../utils/utils";
import DatePicker from "../shared/DatePicker";
import FormActionButton from "../shared/FormActionButton";
import ItemsRadioOptions from "../shared/ItemsRadioOptions";
import Select from "../shared/Select";
import Text from "../shared/Text";

export default function ContactDetails({compensate, onNext, onBack}){
    const [emailAddress, setEmailAddress] = useState(compensate.emailAddress)
    const [mobileNumber, setMobileNumber] = useState(compensate.mobileNumber)
    const [landline, setLandline] = useState(compensate.landline)
    const intl = useIntl();

    return (
        <>
            <div className="heading">
                <FormattedMessage id="Please provide us with your contact information"/>
            </div>
            <div className="form-content">
                
                <Text
                    label={intl.formatMessage({id: "What is your email address?"})}
                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                    onChange={(e)=>setEmailAddress(e.target.value)}
                    value={emailAddress}
                />

                <Text 
                    label={intl.formatMessage({id: "What is your mobile number?"})}
                    onChange={(e)=>setMobileNumber(e.target.value)}
                    value={mobileNumber}
                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                />

                <Text 
                    label={intl.formatMessage({id: "What is your landline number?"})}
                    onChange={(e)=>setLandline(e.target.value)}
                    value={landline}
                    style={{border: 'none', borderBottom: '1px solid #707070'}}
                />

                <div className="info">
                    <FormattedMessage id="We ask for this information to make sure we can keep you updated on your claim."/>
                </div>
            </div>
            <FormActionButton
                onNext={()=>{
                    if(!isValidEmail(emailAddress)){
                        alert("Please enter valid email address")
                    }else if(!isValidMobile(mobileNumber)){
                        alert("Please enter valid mobile number")
                    }else if(!isValidLandline(landline)){
                        alert("Please enter valid landline number")
                    }else{
                        onNext({
                            emailAddress,
                            mobileNumber,
                            landline,
                        }, (COMPENSATE_SCREEN.CONTACT_DETAILS+( compensate.passengerCountInParty != "1" ? 1 : 2 )))
                    }
                }}
                onBack={()=>{
                    onBack(COMPENSATE_SCREEN.CONTACT_DETAILS-1)
                }}
            />
        </>
    )
}