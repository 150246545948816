import React from "react";

const Scenarios = () => {
    return (
        <div className="scenarios-wrapp gap-top gb">
            <div className="position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-7">
                                    {/* <div class="text-md theme-color-2 fm">Scenarios you can claim</div> */}
                                    <h2 className="title theme-color-2">Extraordinary Circumstances <br />
                                    <span className="theme-color-1">you can claim!</span> </h2>
                                    <ul className="d-flex flex-wrap arrow-style-list circle-arrow mb-0 ps-0 gap-top-sm li-100 black-text gap-top-sm">
                                        <li>Airline staff e.g. pilots, cabin crew are late or the
                                            flight is understaffed.</li>
                                        <li>Bad weather that affected a previous flight
                                            meaning your aircraft was delayed.</li>
                                        <li>Denied boarding due to the flight being overbooked.</li>
                                        <li>Technical problems with the aircraft (except hidden
                                            manufacturing defects or problems caused
                                            by sabotage).</li>
                                        <li>Any other situation outside of the ‘extraordinary
                                            circumstances’ listed above.</li>
                                    </ul>
                                </div>
                                <div className="col-md-5 scen-image-block">
                                    <img src="img/sce-1.png" alt="" className="max-w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="circle md orange left"></div>
                <div class="circle md orange right center"></div> */}
            </div>
            <div className="position-relative">
                <div className="container gap-top">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-5 order-2-sm scen-image-block">
                                    <img src="img/sce-2.png" alt="" className="max-w-100" />
                                </div>
                                <div className="col-md-7 order-1-sm">
                                    {/* <div class="text-md theme-color-2 fm">Scenarios you can’t claim</div> */}
                                    <h2 className="title theme-color-2">Extraordinary Circumstances <br />
                                    <span className="theme-color-1">you can't claim!</span> </h2>
                                    <ul className="d-flex flex-wrap arrow-style-list circle-arrow info-list mb-0 ps-0 gap-top-sm li-100 black-text gap-top-sm">
                                        <li>Acts of terrorism or sabotage.</li>
                                        <li>Industrial action (strikes unrelated to airline
                                            such as baggage handlers, or air traffic control).</li>
                                        <li>Political or civil unrest.</li>
                                        <li>Security risks.</li>
                                        <li>Extreme weather conditions e.g. the eruption
                                            of the volcano Eyjafjallajokull in 2010</li>
                                        <li>Hidden manufacturing defects.</li>
                                        <li>Bird Strikes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="circle md blue left"></div>
                <div class="circle md blue right bottom"></div> */}
            </div>
        </div>
    )
}

export default Scenarios;